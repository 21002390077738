<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <div class="col-md-6 col-lg-4">
              <b-form-input type="search" placeholder="Type to search" @input="asyncFind" />
              <small v-if="isLoadingSearch"> <em> typing ... </em> </small>
            </div>
          </div>
          <div class="row justify-content-end mb-1">
            <span class="pr-2">
              <router-link class="btn btn-sm btn-dark" :to="{name: SingleCreatePath.name}">
                <i class="fa fa-plus"></i>
              </router-link>
            </span>
            <span>
              <button class="btn btn-sm btn-dark" @click="actionGetOffers">
                <i class="fa fa-refresh"></i>
              </button>
            </span>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ totalRows }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            :tbody-tr-class="rowClass"
            responsive="xl"
            show-empty
            hover
          >
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
            <template #head()="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(name)="row">
              <div style="width: 300px;">
                {{ row.item.name }}
                <strong>#{{ row.item.id }}</strong>
              </div>
            </template>
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1)}}
            </template>
            <template #cell(type)="row">
              {{ OfferType(row.item.offer_type_id) }}
            </template>
              <template #cell(price_idr)="row">
              {{ formatPrice(row.item.price_idr,"IDR") }}
            </template>
              <template #cell(price_usd)="row">
              {{ formatPrice(row.item.price_usd,"USD") }}
            </template>
            <template #cell(is_free)="row">
              <div>
                <span v-if="row.item.is_free" class="badge badge-success">
                    Y
                  </span>
                  <span v-else class="badge badge-danger"> N </span>
              </div>
            </template>
            <template #cell(offer_status)="row">
              {{ OfferStatus(row.item.offer_status) }}
            </template>
            <template #cell(action_edit)="row">
              <router-link class="btn btn-default"
                :to="{ name: SingleEditPath.name, params:{ id:row.item.id }}"
              >
                <i class="fa fa-edit">
                </i>
              </router-link>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1 pt-4">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { SingleCreatePath, SingleEditPath } from '../../router/marketing';
import constant from '../../store/constant';
export default {
  name: 'discount',
  data() {
    return {
      SingleCreatePath,
      SingleEditPath,
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      debounce: null,
      items_search: [],
      fields: [
        { key:'No', label: 'No'},
        {
          key:'name',
          label: 'Name',
        },
        // { key:'item_code', label: 'Item Code'},
        {
          key:'price_idr',
          label: 'Web Price IDR',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key:'price_usd',
          label: 'Web Price USD',
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          key:'is_free',
          label: 'Is Free',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { 
          key:'offer_status',
          label: 'Offer Status',
          class: "text-nowrap",
        },
        {
          key:'created',
          label: 'Created',
          formatter:function(value) {
            return moment(value).format('DD-MM-YYYY')},
          class: "text-nowrap",
        },
        { key :'action_edit', label:'Edit'},
      ]
    };
  },
  computed: {
    ...mapGetters('offers', { items: 'offers' }),
    ...mapState({
      isLoading: state => state.offers.isLoading,
      isError: state => state.offers.isError,
      totalRows: state => state.offers.totalRows
    })
  },
  mounted() {
    this.actionGetOffers();
  },
  watch: {
    currentPage: function() {
      this.actionGetOffers();
    }
  },
  methods: {
    ...mapActions('offers', ['fetchOffers', 'searchOffers', 'fetchOffersById']),
    actionGetOffers() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage
      };

      this.fetchOffers(payload);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMM YY hh:mm');
    },
    formatDateValid(from, to) {
      return (
        moment(from).format('DD MMM YY') +
        ' - ' +
        moment(to).format('DD MMM YY')
      );
    },
    OfferType(key) {
      if (key == 1) {
        return 'Single';
      } else if (key == 2) {
        return 'Subscriptions';
      } else if (key == 3) {
        return 'Bundle';
      } else if (key == 4) {
        return 'Buffet';
      }
    },
    OfferStatus(key) {
      if (key == 1) {
        return 'New';
      } else if (key == 2) {
        return 'Waitting For Review';
      } else if (key == 3) {
        return 'In Review';
      } else if (key == 4) {
        return 'Rejected';
      } else if (key == 5) {
        return 'Approved';
      } else if (key == 6) {
        return 'Prepare for sale';
      } else if (key == 7) {
        return 'Ready For Sale';
      } else if (key == 8) {
        return 'Not For Sale';
      }
    },
    asyncFind(query) {
      if (!query) return;

      this.isLoadingSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchOffers({name: query}).then((response) => {
          this.items_search = response.data.data.rows;
          this.isLoadingSearch = false;
        }).catch(() => {
          this.isLoadingSearch = false;
        });
      }, 600);
    },
    asyncSelected(data){
      this.fetchOffersById(data.id);
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },
    formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == 'USD') {
            formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat('en-ID', {
              style: 'currency',
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return '0';
    },
    rowClass(item) {
      if (!item || !item?.offer_status) return;
      if (item.offer_status === constant.OFFER_STATUS.NOT_FOR_SALE) return 'table-danger';
    }
  }
};
</script>

<style scoped>
</style>
